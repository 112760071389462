@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&display=swap');
* {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, San Francisco,
    Segoe UI, Roboto, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-weight: 500;
  background: #f4f6f9 !important;
}

.student-profile {
  background-color: #2c3a47 !important;
}

.service-card {
  cursor: pointer;
}

.service-card:hover {
  background-color: var(--bs-primary);
  color: var(--bs-white) !important;
}

.sidebar .nav-link {
  padding: 8px 10px !important;
}

.react-tel-input .form-control {
  font-size: 1rem !important;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: var(--bs-primary);
}

.float-left {
  float: left;
}

.float-end {
  float: right;
}
.align-centre {
  text-align: center;
}

.error-field {
  color: #9f3a38;
  font-size: 0.8rem;
}

.foot-login {
  margin-top: 23px;
  display: flex !important;
  flex-wrap: nowrap !important;
}

.list-group .list-group-item:focus {
  border-radius: 0px !important;
}

.form-control-xs {
  height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.25rem !important;
  font-size: 0.75rem !important;
  border-radius: 0.2rem;
}

.react-tel-input .form-control {
  font-size: 14px !important;
}

.text-xs {
  font-size: 10px !important;
}

.text-sm {
  font-size: 12px !important;
}

.text-md {
  font-size: 12px !important;
}

.font500 {
  font-weight: 500 !important;
}

.font600 {
  font-weight: 600 !important;
}

.font600 {
  font-weight: 700 !important;
}

.card {
  border: none;
  background-color: #fff;
}

.card-header {
  padding: 16px;
  background: var(--bs-white) !important;
  display: flex;
  align-items: center;
}

.card-options {
  margin-left: auto !important;
  display: flex !important;
  order: 100 !important;
  align-self: center !important;
}

@media only screen and (max-width: 992px) {
  .card-header {
    display: block;
    align-items: left;
  }

  .card-options {
    margin-left: 0;
    display: block;
    align-self: left;
    margin-top: 0.5em;
  }
}

.table-responsive {
  overflow-y: visible !important;
}

.modal-content {
  border-radius: 0% !important;
}

.form-control {
  border-color: var(--bs-secondary);
  color: var(--bs-primary) !important;
}

.form-control:focus {
  border-color: var(--bs-primary);
  box-shadow: none;
}
input[type='checkbox'],
input[type='radio'] {
  margin-right: 4px;
}
input.form-control.input-rounded {
  border-radius: 60px;
}

.row-deck > .col,
.row-deck > [class*='col-'] {
  display: flex;
  align-items: stretch;
}

.row-deck > .col .card,
.row-deck > [class*='col-'] .card {
  flex: 1 1 auto;
}

.checkbox label,
.radio label {
  padding-left: 0;
}

.fixed-top,
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav,
.sb-nav-fixed .sb-topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

element.style {
  padding-left: 0px !important;
}

.rightContainer {
  display: flex;
  align-items: center;
}

.ant-layout-header {
  padding: 0;
  box-shadow: var(--shadow-sm);
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 64px !important;
  z-index: 9;
  align-items: center;
  background-color: #fff;
}

.button {
  width: 64px;
  height: 64px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  transition: ease-in;
  display: inline !important;
}

.button:hover {
  background-color: var(--bs-light);
  color: var(--bs-primary);
}

.ant-breadcrumb > ol {
  list-style: none !important;
  padding-left: 0;
  margin-bottom: 0;
}